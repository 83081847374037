<template>
  <div class="mb-4 mt-4 flex justify-center gap-1 space-x-4">
    <a
      v-for="(item, index) in items"
      :key="index"
      :href="item.url"
      target="_blank"
      class="fa-2x text-primary"
      :aria-label="
        $t('footer.socialLink', { social: getLinkIcon(item.url)?.name })
      "
    >
      <i :class="getLinkIcon(item.url)?.class" />
    </a>
  </div>
</template>

<script setup lang="ts">
import type { LinkItem } from '~/types/cms/common';

defineProps({
  items: {
    required: true,
    type: Object as PropType<LinkItem[]>,
  },
});

function getLinkIcon(url: string) {
  if (url.includes('instagram'))
    return { class: 'fab fa-instagram', name: 'Instagram' };
  if (url.includes('linkedin'))
    return { class: 'fab fa-linkedin', name: 'LinkedIn' };
  if (url.includes('youtube'))
    return { class: 'fab fa-youtube', name: 'YouTube' };
  if (url.includes('facebook'))
    return { class: 'fab fa-facebook', name: 'Facebook' };
  if (url.includes('pinterest'))
    return { class: 'fab fa-pinterest', name: 'Pinterest' };
  if (url.includes('twitter'))
    return { class: 'fab fa-x-twitter', name: 'Twitter' };
}
</script>
